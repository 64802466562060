import { StaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import HamburgerButton from "./HamburgerButton";
import logo from '../images/jake-white-logo.svg';


class Header extends React.Component {

  constructor(props) {
    super(props);
    this.mainNavigation = React.createRef();
  }

  render() {
    return (
      <header className="header">
        <div className="header-logo">
          <a href="/">
            <img src={logo} alt="Jake White" />
            <span className="sr-only">Jake White</span>
          </a>
        </div>
        <HamburgerButton target={this.mainNavigation} />
        <div ref={this.mainNavigation} className="navigation">
          <nav>
            <Link to="/" className="nav-item">Home</Link>
            <Link to="/projects" className="nav-item">Projects</Link>
            {/* <Link to="/blog">Blog</Link> */}
            <a href="https://www.dropbox.com/s/kld6l0h0fngmkyi/Jake%20White%20Resume%20-%202021.pdf?dl=0" className="nav-item" target="_blank">Resume</a>
            <Link to="/contact" className="nav-item">Contact</Link>
          </nav>
        </div>
      </header>
    )
  }
}


Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
