import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    target : PropTypes.object.isRequired,
};



const classNames = ["hamburger", "hamburger--minus"];

export default class HamburgerButton extends React.Component {

    state = { active: false, classes: classNames };

    toggleClass() {
        const currentState = this.state.active;
        const currentClasses = this.state.classes;
        const menu = this.props.target;


        if(false === currentState){
            currentClasses.push("is-active");
            this.setState({ classes: currentClasses, active: true})
            menu.current.style.display = "block";
        }else{
            currentClasses.splice(2);
            this.setState({ classes: classNames, active: false })
            menu.current.style.display = "none";
        }

        
    }




    render() {
        return (
            <React.Fragment>
                <button  onClick={this.toggleClass.bind(this)} className={this.state.classes.join(" ")} type="button">
                    <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                    </span>
                </button>
            </React.Fragment>
        );
    }
}

 HamburgerButton.propTypes = propTypes;