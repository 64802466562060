import React from 'react';
import { Link } from "gatsby";

class Footer extends React.Component {
    render() {
        return <section className="closing-section">
            <div className="interested">
                <h3>Interested in working together?</h3>
                <Link to="/contact" className="btn btn-blue">Let's Connect</Link>
            </div>
            <footer>&copy; {new Date().getFullYear()} Jake White. Built with Gatsby and WordPress.</footer>
        </section>;
    }
}

export default Footer;